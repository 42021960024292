import { template as template_5a39c5acaed94a429ddae25dd699dd02 } from "@ember/template-compiler";
const FKLabel = template_5a39c5acaed94a429ddae25dd699dd02(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
